:root {
  --body-text-color: var(--color-text-medium);
  --70-percent-black: var(--color-text-dark);
}

/* center */
:global(.text-centered),
.text-centered {
  text-align: center;
}

@media screen and (--breakpoint-min-sm) {
  :global(.sm-text-centered) {
    text-align: center;
  }
}

@media screen and (--breakpoint-min-md) {
  :global(.md-text-centered) {
    text-align: center;
  }
}

@media screen and (--breakpoint-min-lg) {
  :global(.lg-text-centered) {
    text-align: center;
  }
}

@media screen and (--breakpoint-min-xl) {
  :global(.xl-text-centered) {
    text-align: center;
  }
}

:global(.text-left),
.text-left {
  text-align: left;
}

@media screen and (--breakpoint-min-sm) {
  :global(.sm-text-left) {
    text-align: left;
  }
}

@media screen and (--breakpoint-min-md) {
  :global(.md-text-left) {
    text-align: left;
  }
}

@media screen and (--breakpoint-min-lg) {
  :global(.lg-text-left) {
    text-align: left;
  }
}

@media screen and (--breakpoint-min-xl) {
  :global(.xl-text-left) {
    text-align: left;
  }
}

:global(.text-right),
.text-right {
  text-align: right;
}

@media screen and (--breakpoint-min-sm) {
  :global(.sm-text-right) {
    text-align: right;
  }
}

@media screen and (--breakpoint-min-md) {
  :global(.md-text-right) {
    text-align: right;
  }
}

@media screen and (--breakpoint-min-lg) {
  :global(.lg-text-right) {
    text-align: right;
  }
}

@media screen and (--breakpoint-min-xl) {
  :global(.xl-text-right) {
    text-align: right;
  }
}

:global(.text-uppercase),
.text-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

:global(.text-lowercase) {
  text-transform: lowercase;
}

:global(.text-capitalize) {
  text-transform: capitalize;
}

:global(.text-transform-none) {
  text-transform: none;
}

:global(.text-normal) {
  font-weight: 400;
}

:global(.text-bold),
.text-bold {
  font-weight: 700;
}

:global(.text-heavy) {
  font-weight: 900;
}

/* text style */

:global(.text-italic) {
  font-style: italic;
}

/* larger text size used for descriptions  */
:global(.text-body),
.text-body {
  font-size: 1.286em;
  line-height: 1.457em;
  color: var(--color-text-medium); /* TODO - is this bad? */
}

:global(.text-list) {
  font-size: 1em;
}

:global(.text-paragraph),
.text-paragraph {
  font-size: 1.143em;
  line-height: 1.5em;
}

:global(.text-spaced),
.text-spaced {
  line-height: 1.5em;
}

:global(.text-unspaced),
.text-unspaced {
  line-height: normal;
}

:global(.text-small) {
  font-size: 0.875em;
}

:global(.text-smaller) {
  font-size: 0.8em;
}

:global(.text-current) {
  color: currentColor;
}

:global(.text-underline) {
  text-decoration: underline;
}

:global(.text-underline-hover:hover) {
  text-decoration: underline;
}

:global(.text-ellipsis) {
  text-overflow: ellipsis;
}

:global(.text-wrap) {
  overflow-wrap: anywhere;
  word-break: break-word;
  word-wrap: anywhere;
}

:global(.text-nowrap) {
  white-space: nowrap;
}

:global(.text-code) {
  font-family: monospace;
  color: var(--color-text-medium);
  background-color: var(--color-bg-medium);
  border-radius: 2px;
  padding: 0.2em 0.4em;
  line-height: 1.4em;
  white-space: pre-wrap;
}

:global(.text-code-plain) {
  font-family: monospace;
  color: var(--color-text-dark);
  line-height: 1.4em;
  white-space: pre-wrap;
}

:global(.text-monospace),
.text-monospace {
  font-family: Monaco, monospace;
}

:global(.text-pre-wrap) {
  white-space: pre-wrap;
}

:global(.text-measure) {
  max-width: 620px;
}

:global(.break-anywhere) {
  line-break: anywhere;
}
