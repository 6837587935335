:global(.hide) {
  display: none !important;
}

:global(.show) {
  display: inherit;
}

:global(.hidden) {
  visibility: hidden;
}

:global(.sm-show),
:global(.md-show),
:global(.lg-show),
:global(.xl-show) {
  display: none;
}

/* extra-small */

@media screen and (--breakpoint-min-xs) {
  :global(.xs-hide) {
    display: none !important;
  }
}

@media screen and (--breakpoint-min-xs) {
  :global(.xs-show) {
    display: inherit !important;
  }
}

/* small */

@media screen and (--breakpoint-min-sm) {
  :global(.sm-hide) {
    display: none !important;
  }
}

@media screen and (--breakpoint-min-sm) {
  :global(.sm-show) {
    display: inherit !important;
  }
}

/* medium */

@media screen and (--breakpoint-min-md) {
  :global(.md-hide) {
    display: none !important;
  }
}

@media screen and (--breakpoint-min-md) {
  :global(.md-show) {
    display: inherit !important;
  }
}

/* large */

@media screen and (--breakpoint-min-lg) {
  :global(.lg-hide) {
    display: none !important;
  }
}

@media screen and (--breakpoint-min-lg) {
  :global(.lg-show) {
    display: inherit !important;
  }
}

/* xl */
@media screen and (--breakpoint-min-xl) {
  :global(.xl-hide) {
    display: none !important;
  }
}

@media screen and (--breakpoint-min-xl) {
  :global(.xl-show) {
    display: inherit !important;
  }
}
