/*
  display
  hide and show a child element using display
*/
:global(.hover-parent.hover--display .hover-child),
:global(.hover-parent:hover.hover--display .hover-child--hidden) {
  display: none;
}

:global(.hover-parent:hover.hover--display .hover-child) {
  display: block;
}

/*
  visibility
  hide and show a child element using visibility
*/
:global(.hover-parent.hover--visibility .hover-child),
:global(.hover-parent:hover.hover--visibility .hover-child--hidden) {
  visibility: hidden;
}

:global(.hover-parent:hover.hover--visibility .hover-child) {
  visibility: visible;
}

:global(.hover-parent:hover.hover--inherit > *),
:global(.hover-parent:hover.hover--inherit .hover-child) {
  color: inherit !important;
}

:global(.hover-child--smooth) {
  transition: opacity 0.2s ease-in-out;
}

@media (prefers-reduced-motion) {
  :global(.hover-child--smooth) {
    transition: none;
  }
}

:global(.hover-parent.hover--display .hover-child--smooth),
:global(.hover-parent.hover--visibility .hover-child--smooth) {
  opacity: 0;
}

:global(.hover-parent:hover.hover--display .hover-child--smooth),
:global(.hover-parent:hover.hover--visibility .hover-child--smooth) {
  opacity: 1;
}
