:root {
  --table-border-radius: 6px;
}

:global(.TableInteractive) {
  color: #4f575d;
  overflow: hidden;
}

:global(.TableInteractive-headerCellData .cellData) {
  font-weight: 900;
  font-size: 10px;
  border: 1px solid transparent;
  padding: 0.25em 0.65em;
  border-radius: 6px;
  min-width: 35px;
  color: var(--color-brand);
}

:global(.TableInteractive-headerCellData .cellData:hover) {
  border: 1px solid transparent;
}

:global(.TableInteractive-headerCellData .Icon-chevrondown),
:global(.TableInteractive-headerCellData .Icon-chevronup) {
  opacity: 0.5;
}

:global(.TableInteractive-headerCellData--sorted .Icon-chevrondown),
:global(.TableInteractive-headerCellData--sorted .Icon-chevronup) {
  opacity: 1;
  transition: opacity 0.3s linear;
}

:global(.TableInteractive-header) {
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-border);
}

:global(.TableInteractive .TableInteractive-cellWrapper) {
  overflow: hidden;
  display: flex;
  align-items: center;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid color-mod(var(--color-border) alpha(-70%));
}

:global(.TableInteractive .TableInteractive-cellWrapper--active) {
  z-index: 1;
}

:global(.TableInteractive .TableInteractive-header),
:global(.TableInteractive
    .TableInteractive-header
    .TableInteractive-cellWrapper),
:global(.TableInteractive
    .TableInteractive-header
    .TableInteractive-cellWrapper:hover) {
  background-color: var(--color-bg-white);
  background-image: none;
}

:global(.TableInteractive .TableInteractive-header),
:global(.TableInteractive
    .TableInteractive-header
    .TableInteractive-cellWrapper) {
  background-color: var(--color-bg-white);
}

/* cell overflow ellipsis */
:global(.TableInteractive .cellData) {
  margin: 0 0.75em;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  font-weight: 700;
  font-size: 12.5px;
}

/* pivot */
:global(.TableInteractive.TableInteractive--pivot
    .TableInteractive-cellWrapper--firstColumn) {
  border-right: 1px solid var(--color-border);
}

:global(.PagingButtons) {
  border: 1px solid var(--color-border);
}

:global(.TableInteractive
    .TableInteractive-header
    .TableInteractive-cellWrapper.tether-enabled
    .cellData) {
  background-color: transparent;
  color: white !important;
}

:global(.TableInteractive-cellWrapper:hover) {
  background-color: transparent;
}

:global(.Table-ID .cellData) {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 0.25em 0.65em;
  border-radius: 99px;
  min-width: 35px;
  text-align: center;
  color: var(--color-brand);
}

:global(.TableInteractive-ID.TableInteractive-cellWrapper:hover),
:global(.TableInteractive-ID.TableInteractive-cellWrapper.tether-enabled) {
  background-color: transparent;
}

:global(.TableInteractive-ID.TableInteractive-cellWrapper:hover .cellData),
:global(.TableInteractive-ID.TableInteractive-cellWrapper.tether-enabled
    .cellData) {
  background-color: var(--color-brand);
  color: white;
}

:global(.TableInteractive-headerCellData--sorted) {
  color: var(--color-brand);
}

:global(.TableInteractive-cellWrapper--firstColumn.padLeft) {
  padding-left: 1.5em;
}

:global(.TableInteractive-gutter) {
  background-color: var(--color-bg-white);
}

:global(.TableInteractive-detailButton) {
  color: var(--color-brand);
  transition: opacity 0.2s ease-in-out;
  margin: 0 4px;
  opacity: 0;
}

:global(.show .TableInteractive-detailButton) {
  opacity: 1;
}
