body {
  background-color: transparent;
}

:global(.EmbedFrame) {
  background-color: white;
}

:global(.EmbedFrame-header),
:global(.EmbedFrame-footer) {
  color: var(--color-text-dark);
  background-color: white;
}

:global(.Theme--night.EmbedFrame) {
  background-color: var(--color-bg-black);
  border: 1px solid var(--color-bg-dark);
}

:global(.Theme--night .EmbedFrame-header),
:global(.Theme--night .EmbedFrame-footer) {
  color: color-mod(var(--color-text-white) alpha(-14%));
  background-color: var(--color-bg-black);
  border-color: var(--color-bg-dark);
}

:global(.Theme--night.EmbedFrame .fullscreen-night-text) {
  color: color-mod(var(--color-text-white) alpha(-14%));
  transition: color 1s linear;
}

:global(.Theme--night.EmbedFrame svg text) {
  fill: color-mod(var(--color-text-white) alpha(-14%)) !important;
  stroke: none !important;
}

:global(.Theme--night.EmbedFrame .DashCard .Card) {
  background-color: var(--color-bg-black);
  border: 1px solid var(--color-bg-dark);
}

:global(.Theme--night.EmbedFrame
    .enable-dots-onhover
    .dc-tooltip
    circle.dot:hover),
:global(.Theme--night.EmbedFrame .enable-dots .dc-tooltip circle.dot) {
  fill: currentColor;
}

:global(.Theme--transparent.EmbedFrame) {
  background-color: transparent;
}

:global(.Theme--transparent .EmbedFrame-header),
:global(.Theme--transparent .EmbedFrame-footer) {
  background-color: transparent;
}

:global(.Theme--transparent.EmbedFrame .DashCard .Card) {
  background-color: transparent;
}
