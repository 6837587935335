:root {
  --sm-width: 752px;
  --md-width: 940px;
  --lg-width: 1140px;
  --xl-width: 1540px;
}

/* When converting to styled components, use FullWidthContainer */
:global(.wrapper),
.wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
}

@media screen and (--breakpoint-min-sm) {
  :global(.wrapper),
  .wrapper {
    padding-left: 2em;
    padding-right: 2em;
  }
}

@media screen and (--breakpoint-min-md) {
  :global(.wrapper),
  .wrapper {
    padding-left: 3em;
    padding-right: 3em;
  }
}

/* set height full relative to the parent */
:global(.full-height),
.full-height {
  height: 100%;
}

/* display utilities */
:global(.block),
.block {
  display: block;
}

:global(.inline),
.inline {
  display: inline;
}

:global(.inline-block),
.inline-block {
  display: inline-block;
}

:global(.table) {
  display: table;
}

:global(.full),
:global(.full-width),
.full,
.full-width {
  width: 100%;
}

:global(.half) {
  width: 50%;
}

/* position utilities */
:global(.fixed),
.fixed {
  position: fixed;
}

:global(.relative),
.relative {
  position: relative;
}

:global(.absolute),
.absolute {
  position: absolute;
}

:global(.top),
.top {
  top: 0;
}

:global(.right),
.right {
  right: 0;
}

:global(.bottom),
.bottom {
  bottom: 0;
}

:global(.left),
.left {
  left: 0;
}

@media screen and (--breakpoint-min-md) {
  :global(.wrapper.wrapper--trim),
  .wrapper.wrapper--trim {
    max-width: var(--md-width);
  }
}

/* fully fit the parent element - use as a base for app-y pages like QB or settings */
:global(.spread),
.spread {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* force a stacking context for adding z-index to children */
:global(.stacking-context) {
  transform: scale(1);
}
