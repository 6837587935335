/* TODO - ideally this would be more reusable and not hardcode a value */
/* stylelint-disable-next-line selector-type-no-unknown */
:global @keyframes progress-bar {
  from {
    transform: translate3d(0, 0, 0, 0);
  }

  to {
    transform: translate3d(1000px, 0, 0);
  }
}
