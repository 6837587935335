/* lato-regular - latin */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url("~fonts/Lato/lato-v16-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Lato Regular"), local("Lato-Regular"),
    url("~fonts/Lato/lato-v16-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("~fonts/Lato/lato-v16-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("~fonts/Lato/lato-v16-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("~fonts/Lato/lato-v16-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("~fonts/Lato/lato-v16-latin-regular.svg#Lato") format("svg"); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url("~fonts/Lato/lato-v16-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Lato Bold"), local("Lato-Bold"),
    url("~fonts/Lato/lato-v16-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("~fonts/Lato/lato-v16-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("~fonts/Lato/lato-v16-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("~fonts/Lato/lato-v16-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("~fonts/Lato/lato-v16-latin-700.svg#Lato")
      format("svg"); /* Legacy iOS */
}

/* lato-900 - latin */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  src: url("~fonts/Lato/lato-v16-latin-900.eot"); /* IE9 Compat Modes */
  src: local("Lato Black"), local("Lato-Black"),
    url("~fonts/Lato/lato-v16-latin-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("~fonts/Lato/lato-v16-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("~fonts/Lato/lato-v16-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("~fonts/Lato/lato-v16-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("~fonts/Lato/lato-v16-latin-900.svg#Lato")
      format("svg"); /* Legacy iOS */
}

/* PT Serif 400 */
@font-face {
  font-family: "PT Serif";
  src: local("PT Serif"), local("PTSerif-Regular"),
    url("~fonts/PT_Serif/PTSerif-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* PT Serif 700 */
@font-face {
  font-family: "PT Serif";
  src: local("PT Serif Bold"), local("PTSerif-Bold"),
    url("~fonts/PT_Serif/PTSerif-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Merriweather-400 */
@font-face {
  font-family: Merriweather;
  src: local("Merriweather Regular"), local("Merriweather-Regular"),
    url("~fonts/Merriweather/Merriweather-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Merriweather-700 */
@font-face {
  font-family: Merriweather;
  src: local("Merriweather Bold"), local("Merriweather-Bold"),
    url("~fonts/Merriweather/Merriweather-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Merriweather-400 */
@font-face {
  font-family: Merriweather;
  src: local("Merriweather Black"), local("Merriweather-Black"),
    url("~fonts/Merriweather/Merriweather-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("~fonts/Montserrat/Montserrat-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("~fonts/Montserrat/Montserrat-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: local("Montserrat Black"), local("Montserrat-Black"),
    url("~fonts/Montserrat/Montserrat-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("~fonts/Open_Sans/OpenSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("~fonts/Open_Sans/OpenSans-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Oswald;
  src: local("Oswald Bold"), local("Oswald-Bold"),
    url("~fonts/Oswald/Oswald-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Oswald;
  src: local("Oswald Regular"), local("Oswald-Regular"),
    url("~fonts/Oswald/Oswald-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Raleway;
  src: local("Raleway Bold"), local("Raleway-Bold"),
    url("~fonts/Raleway/Raleway-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Raleway;
  src: local("Raleway Regular"), local("Raleway-Regular"),
    url("~fonts/Raleway/Raleway-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Raleway;
  src: local("Raleway Black"), local("Raleway-Black"),
    url("~fonts/Raleway/Raleway-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto Black"), local("Roboto-Black"),
    url("~fonts/Roboto/Roboto-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("~fonts/Roboto/Roboto-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto"), local("Roboto-Regular"),
    url("~fonts/Roboto/Roboto-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"),
    url("~fonts/Roboto_Condensed/RobotoCondensed-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"),
    url("~fonts/Roboto_Condensed/RobotoCondensed-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Slabo 27px";
  src: local("Slabo 27px"), local("Slabo27px-Regular"),
    url("~fonts/Slabo_27px/Slabo27px-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"),
    url("~fonts/Source_Sans_Pro/SourceSansPro-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"), local("SourceSansPro-Regular"),
    url("~fonts/Source_Sans_Pro/SourceSansPro-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url("~fonts/Source_Sans_Pro/SourceSansPro-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lora;
  src: local("Lora Bold"), local("Lora-Bold"),
    url("~fonts/Lora/Lora-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lora;
  src: local("Lora Regular"), local("Lora-Regular"),
    url("~fonts/Lora/Lora-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Black"), local("NotoSans-Black"),
    url("~fonts/Noto_Sans/NotoSans-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Regular"), local("NotoSans-Regular"),
    url("~fonts/Noto_Sans/NotoSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Bold"), local("NotoSans-Bold"),
    url("~fonts/Noto_Sans/NotoSans-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Regular"), local("PlayfairDisplay-Regular"),
    url("~fonts/Playfair_Display/PlayfairDisplay-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Bold"), local("PlayfairDisplay-Bold"),
    url("~fonts/Playfair_Display/PlayfairDisplay-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Black"), local("PlayfairDisplay-Black"),
    url("~fonts/Playfair_Display/PlayfairDisplay-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url("~fonts/Poppins/Poppins-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("~fonts/Poppins/Poppins-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: local("Poppins Black"), local("Poppins-Black"),
    url("~fonts/Poppins/Poppins-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PT Sans";
  src: local("PT Sans"), local("PTSans-Regular"),
    url("~fonts/PT_Sans/PTSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PT Sans";
  src: local("PT Sans Bold"), local("PTSans-Bold"),
    url("~fonts/PT_Sans/PTSans-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono Bold"), local("RobotoMono-Bold"),
    url("~fonts/Roboto_Mono/RobotoMono-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono Regular"), local("RobotoMono-Regular"),
    url("~fonts/Roboto_Mono/RobotoMono-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Slab";
  src: local("Roboto Slab Black"), local("RobotoSlab-Black"),
    url("~fonts/Roboto_Slab/RobotoSlab-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Slab";
  src: local("Roboto Slab Regular"), local("RobotoSlab-Regular"),
    url("~fonts/Roboto_Slab/RobotoSlab-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Slab";
  src: local("Roboto Slab Bold"), local("RobotoSlab-Bold"),
    url("~fonts/Roboto_Slab/RobotoSlab-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Ubuntu;
  src: local("Ubuntu Bold"), local("Ubuntu-Bold"),
    url("~fonts/Ubuntu/Ubuntu-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Ubuntu;
  src: local("Ubuntu Regular"), local("Ubuntu-Regular"),
    url("~fonts/Ubuntu/Ubuntu-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: local("Inter Regular"), local("Inter-Regular"),
    url("~fonts/Inter/Inter-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: local("Inter Bold"), local("Inter-Bold"),
    url("~fonts/Inter/Inter-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: local("Inter Black"), local("Inter-Black"),
    url("~fonts/Inter/Inter-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
