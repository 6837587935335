:root {
  --default-border-radius: 8px;
}

:global(.rounded),
.rounded {
  border-radius: var(--default-border-radius);
}

:global(.circular) {
  border-radius: 99px !important;
}
